export default {
  ImperialShooter: 'Имперский стрелок',
  MasterShooter: 'Мастер стрелок',
  YoungPriestess: 'Молодая жрица',
  WarriorOfLight: 'Воин света',
  ValorKnight: 'Благородный рыцарь',
  Bannerman: 'Знаменосец',
  Defender: 'Заступник',
  RighteousFighter: 'Праведная воительница',
  KnightGuardian: 'Рыцарь-защитник',
  ImperialGriffon: 'Имперский Гриффон',
  TemplarChampion: 'Тамплиер Чемпион',
  MasterOfLight: 'Магистр света',
  YouthFountain: 'Фонтан молодости',
  RetaliationHammer: 'Молот возмездия',
  TemplarChampCast: 'Заклинание',
  BannermanCast: 'Заклинание',
  ZigfridHero: 'Зигфрид - Герой',
  AgielHero: 'Агиель - Герой',
  Morale: 'Мораль',
  RetributionSword: 'Меч возмездия',
};
