export default {
  ThunderBlast: 'Атаковать воина силой 4 и оглушить союзных и вражеских воинов прилегающих к цели',
  Swiftness: 'Накладывается на союзного воина. Он получает СТРЕМИТЕЛЬНОСТЬ',
  LightningStrike: 'Атаковать воина силой 4 или 5, если есть ваши наложенные заклинания воздуха на поле',
  Dodge: 'РЕАКЦИЯ. Герой игнорирует первый урон по нему за раунд',
  ShockWaveCast: 'Выберите пустую клетку для применения',
  LightningPower: 'Накладывается на вашего воина. Он получает СТРЕМИТЕЛЬНОСТЬ и ПРОНИКАЮЩАЮ АТАКУ 2',
  ThunderWeapon: 'Накладывается на воина, он получает УДАРНУЮ ВОЛНУ 2',
  ArrowsRain: 'Кладется рядом с полем. Ваши Стрелки могут атаковать любого воина на поле',
  AirFlows: 'Кладется на центральную клетку. Союзные воины в этой шеренге получаю СТРЕМИТЕЛЬНОСТЬ в этом ходу',
};
