export default {
  ThunderBlast: 'Удар грома',
  Swiftness: 'Стремительность',
  LightningStrike: 'Удар молнии',
  Dodge: 'Уворот',
  LightningPower: 'Сила молний',
  ThunderWeapon: 'Оружие грома',
  ArrowsRain: 'Дождь из стрел',
  ShockWaveCast: 'Оружие грома',
  AirFlows: 'Воздушные потоки',
};
