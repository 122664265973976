export default {
  BattleCry: 'Боевой клич',
  MeleeCombat: 'Двойные кинжалы',
  RangeCombat: 'Дальний бой',
  Protection: 'Тяжелая броня',
  Resistance: 'Сопротивление магии',
  Durability: 'Стойкость',
  FirstAid: 'Первая помощь',
  Leadership: 'Лидерство',
  BattleTactics: 'Тактика',
  RighteousHammerCast: 'Молот правосудия',
  RighteousHammer: 'Молот правосудия',
  AddAttackCast: 'Заклинание',
  PlateArmor: 'Латные доспехи',
  LeatherArmor: 'Кожаные доспехи',
};
