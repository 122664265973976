export default {
  FireArrow: 'Огненная стрела',
  MeteorShower: 'Метеоритный дождь',
  FireBall: 'Огненный шар',
  PowerOfFire: 'Сила огня',
  Rage: 'Ярость',
  ExplosiveTrap: 'Взрывная ловушка',
  FireSplash: 'Огненный всплеск',
  FireShield: 'Огненный щит',
  FireBlast: 'Огненный взрыв',
  Ignition: 'Воспламенение',
  Blindness: 'Слепота',
};
